<template>
	<div class="row">
		<div class="col-12" v-if="telaAjuda[$route.path.substring(1, $route.path.lastIndexOf('/'))]">
         <div class="alert alert-secondary alert-dismissible fade show mb-2" role="alert">
            <strong>{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
            <span>Configure as unidades de medida disponíveis no sistema, atributo necessário para melhor apresentação de produtos em relatórios.</span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="$store.dispatch('toggleTelaAjuda', $route.path.substring(1, $route.path.lastIndexOf('/')))"></button>
         </div>
      </div>

      <div class="col-12">
			<div class="card mb-2">
            <div class="card-body">
               <div class="row">
                  <div class="col">
							<label><i class="far fa-ruler color-theme font-12 me-1"></i> Filtrar</label>
                     <input type="text" class="form-control" v-model="pesquisa.tipo" maxlength="200" v-focus @keyup.enter="buscarMarcas" placeholder="Busque por tipo da medida" />
                  </div>
                  <div class="w-max-content align-self-end" v-if="dadosUsuario.usuarioPermissoes.includes('Configuração | Editar unidades de medida')">
							<button type="button" class="btn btn-primary w-100 px-sm-5" @click="abrirModal(null)"><i class="far fa-plus font-13 me-1"></i> Adicionar</button>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-12 px-0" v-if="pesquisa.resultado.length > 0">
			<div class="row medidas">
				<medida v-for="(medida, index) in pesquisa.resultado" :key="index" :medida="medida" :index="index" @editar="abrirModal($event)" />
			</div>
      </div>

      <!-- modalMedida -->
      <div class="modal fade" id="modalMedida" tabindex="-1" aria-labelledby="modalMedidaLabel" aria-hidden="true">
         <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalMedidaLabel">{{ modal.id == null ? 'Adicionar' : 'Editar' }} medida {{ modal.id == null ? '' : ('('+ modal.tipo +')') }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="card mb-2">
							<div class="card-body p-12">
								<div class="row">
									<div class="col-12 mb-3">
										<label><i class="far fa-ruler color-theme font-12 me-1"></i> Tipo *</label>
										<input type="text" class="form-control" v-model="modal.tipo" maxlength="200" />
									</div>
									<div class="col-12 align-self-center">
										<div class="form-check mb-0">
											<input type="checkbox" class="form-check-input" id="decimal" v-model="modal.isDecimal">
											<label class="form-check-label" for="decimal"><i class="far fa-info-circle font-13 color-theme mx-1"></i> Decimal</label>
										</div>
									</div>
								</div>
							</div>
						</div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="salvarMedida">Salvar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import medida from '@/components/configuracoes/unidadesMedida/Medida.vue'

export default {
	name: 'Unidades_de_medida',
	data: function () {
		return {
			pesquisa: {'tipo': null, 'resultado': []},
			modal: {'id': null, 'tipo': null, 'isDecimal': false},
		}
	},
	computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
			telaAjuda: state => state.telaAjuda,
         urlRest: state => state.urlRest
      })
   },
	watch: {
		'$route.params.idTab': function () {
			this.buscarMedidas()
      },
      'pesquisa.tipo' : function (val) {
         var value = val.toLowerCase()

         $(".row.medidas > *").filter(function () {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
         })
      }
   },
	components: {
		medida
	},
	methods: {
		abrirModal : function (medida) {
			if (medida == null) {
				Object.assign(this.$data.modal, this.$options.data.apply(this).modal)
			} else {
				this.modal = {'id': medida.id, 'tipo': medida.tipo, 'isDecimal': false}
			}

			$('#modalMedida').modal('show')
		},
		buscarMedidas : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)
			this.$store.dispatch('getUnidadesMedida').then((data) => {
				this.pesquisa.resultado = data
			}).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
		},
		salvarMedida : function () {
			if (this.modal.tipo == null || String(this.modal.tipo).trim().length == 0) {
				this.$toast.fire({
					icon: 'error',
					title: 'Preencha todos os campos obrigatórios'
				});

				return
			}

			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'post',
				url: this.urlRest +'produtos/saveUnidadeMedida',
				headers: {'Content-Type': 'application/json'},
				data: this.modal

			}).then(() => {
				$('#modalMedida').modal('hide')
				this.buscarMedidas()

				this.$toast.fire({
					icon: 'success',
					title: 'Un. Medida salva!'
				});
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else if (error.response.status == 409) {
						this.$toast.fire({
							icon: 'error',
							title: 'Un. Medida já cadastrada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		}
	},
	mounted() {
      this.buscarMedidas()
	}
}

</script>